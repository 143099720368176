import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Our Services | Pazarlama Akademisi Hizmetlerimiz
			</title>
			<meta name={"description"} content={"Sosyal medya yolculuğunuzda rehberiniz – Hizmetlerimizle dijital başarıya ulaşın"} />
			<meta property={"og:title"} content={"Our Services | Pazarlama Akademisi Hizmetlerimiz"} />
			<meta property={"og:description"} content={"Sosyal medya yolculuğunuzda rehberiniz – Hizmetlerimizle dijital başarıya ulaşın"} />
			<meta property={"og:image"} content={"https://rynuxgold.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://rynuxgold.com/img/1104745-200.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://rynuxgold.com/img/1104745-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://rynuxgold.com/img/1104745-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://rynuxgold.com/img/1104745-200.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://rynuxgold.com/img/1104745-200.png"} />
			<meta name={"msapplication-TileImage"} content={"https://rynuxgold.com/img/1104745-200.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="List-2">
			<Box border-radius="24px" margin="0px 0px 40px 0px">
				<Text
					color="--greyD3"
					md-text-align="center"
					margin="0px 0px 0px 0px"
					font="--headline1"
					width="100%"
					letter-spacing="-1px"
					lg-width="100%"
					md-font="--headline3"
				>
					Hizmetlerimizin Öne Çıkanları
				</Text>
				<Text margin="20px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
					Pazarlama Akademisi olarak, sosyal medya pazarlama dünyasında başarıya ulaşmanız için gereken her türlü hizmeti sunuyoruz. En güncel bilgilerden uygulamalı çalışmalara kadar, sizlere özel olarak tasarlanmış kurslarımızla dijital pazarlamada fark yaratmanızı sağlıyoruz.
				</Text>
			</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="35px 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Image src="https://rynuxgold.com/img/7.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text
						color="--greyD3"
						md-text-align="center"
						margin="5px 0px 15px 0px"
						font="--headline3"
						width="100%"
						lg-width="100%"
					>
						Eğitim Programlarımız
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						Sosyal Medya Stratejileri: Markanızın sosyal medya varlığını güçlendirmek için gerekli stratejileri öğrenin. Hedef kitle analizinden içerik yönetimine kadar her şeyi kapsar.
						<br />
						İçerik Pazarlama: Etkileyici içerikler üretmek ve hedef kitlenizi genişletmek için ihtiyacınız olan bilgiler burada. Görsel ve yazılı içerik üretiminin püf noktalarını keşfedin.
						<br />
						Reklam ve Kampanya Yönetimi: Etkili reklam kampanyaları oluşturmayı ve sosyal medya üzerinden hedef kitlenize ulaşmayı öğrenin. ROI'yi maksimize etmenin yollarını keşfedin.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image src="https://rynuxgold.com/img/8.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text
						color="--greyD3"
						md-text-align="center"
						margin="5px 0px 15px 0px"
						font="--headline3"
						width="100%"
						lg-width="100%"
					>
						Özel Çalışma Grupları
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						Uygulamalı Atölye Çalışmaları: Öğrendiklerinizi pratiğe dökün. Gerçek dünya örnekleri üzerinden yapılan bu atölye çalışmaları ile bilginizi pekiştirin.
						<br /><br />
						Soru-Cevap Oturumları: Eğitmenlerimizle doğrudan etkileşime geçin. Merak ettiklerinizi sorun, kariyer yolculuğunuzda size özel tavsiyeler alın.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image src="https://rynuxgold.com/img/9.jpg" display="block" margin="0px 0px 20px 0px" max-width="100%" />
					<Text
						color="--greyD3"
						md-text-align="center"
						margin="5px 0px 15px 0px"
						font="--headline3"
						width="100%"
						lg-width="100%"
					>
						Topluluğa Katılın
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						Ağ Oluşturma: Diğer kursiyerlerle ve sektör profesyonelleriyle tanışma fırsatı. Deneyimlerinizi paylaşın, yeni bağlantılar kurun.
						<br /><br />
						Sürekli Destek: Kurs süresince ve sonrasında da sizlerleyiz. Sosyal medya pazarlama yolculuğunuzda her zaman yanınızdayız.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://rynuxgold.com/img/10.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Başarı Hikayenizi Bizimle Yazın
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Pazarlama Akademisi'yle edindiğiniz bilgi ve tecrübelerle, sosyal medya dünyasında benzersiz bir konuma sahip olun. Hedeflerinize ulaşmak ve dijital pazarlamada başarıyı yakalamak için bizimle iletişime geçin.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});